export const environment = {
  production: true,
  useServiceWorker: false,
  apiBaseUrl: 'https://api-dev2.parenthub.co.uk',
  accountBaseUrl: 'https://account-dev2.parenthub.co.uk',
  testMode: true,
  fcmSenderId: '191418324727',
  aiConnectionString:
    'InstrumentationKey=5299431c-1ad6-462b-8cef-74842cf7e67f;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
  versionName: '2024.07.11.3'
};
